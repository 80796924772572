import api from '@apiInstance';

export default {
  add(data) {
    return api.post('/training_managements', data);
  },
  get(id) {
    return api.fetch('/training_managements/' + id);
  },
  list(params) {
    return api.fetch('/training_managements', { params });
  },
  update(data) {
    return api.patch('/training_managements', data);
  },
  delete(id) {
    return api.remove('/training_managements', id);
  },
};
